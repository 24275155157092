import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ButtonSolid from "../components/Button/ButtonSolid";
import HeroStacked from "../components/Hero/HeroStacked";
import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Small Business Management Services | San Diego | Smart"
            description="Smart Accounting Hub offers small business management services in San Diego and beyond to help owners like you thrive. Learn more & get a quote today!"
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroStacked
            image={data.hero.childImageSharp.gatsbyImageData}
            backgroundFixed={true}
            imageMaxHeight="max-h-[468px]"
            heading="Small Business Management Services"
            subtext="No small business owner should be an island. See how we can help you get more efficient and effective."
            textMaxWidth="max-w-4xl"
         />

         <section className="py-20 md:py-32">
            <div className="container">
               <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} alt="Intro" />
                  </div>
                  <div>
                     <h2>Helping Small Business Owners Thrive</h2>
                     <p>
                        Managing a business can be time-consuming and difficult. That’s where we come in. Smart Accounting Hub offers small business
                        management services in San Diego and beyond to help owners like you thrive. We create new administrative processes or optimize
                        existing ones, and can assist with other tasks to make your business operations sharper than you ever imagined. Lean on our
                        experience, expertise, and friendly service today!
                     </p>
                     <ButtonSolid modal="modal-contact" text="Get a Quote" />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-12 md:mb-16">
                  <h2>Our Business Management Services</h2>
               </header>
               <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
                  <div>
                     <h3 className="text-lg mb-2">Efficient Processes</h3>
                     <p className="mb-0">We organize or create new processes to organize your business’s operations to be more efficient.</p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Administrative Setup</h3>
                     <p className="mb-0">Whatever administrative help your business needs, we can deliver. Lean on our 10+ years of experience.</p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Review & Refer</h3>
                     <p className="mb-0">We can review vendor contracts, expenses, and refer to payroll providers. Our connections can help.</p>
                  </div>
                  <div>
                     <h3 className="text-lg mb-2">Financial Stability</h3>
                     <p className="mb-0">Smart Accounting Hub can improve accounts payable and receivable processes and other needs.</p>
                  </div>
               </div>
            </div>
         </section>

         <WhyUs />

         <Testimonials className="pt-20 md:pt-32 mb-20 md:mb-32" />

         <section className="pb-20 md:pb-32">
            <div className="container">
               <header className="mb-14 md:mb-18">
                  <h2>Related Services</h2>
               </header>
               <div className="grid md:grid-cols-3 md:gap-x-10 gap-y-12">
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.personalBookkeeping.childImageSharp.gatsbyImageData}
                           alt="Personal Bookkeeping Services
                           "
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Personal Bookkeeping</h3>
                     <p className="mb-0">With our personal bookkeeping, stay on track personally so you can thrive professionally.</p>
                     <AniLink fade to="/personal-bookkeeping-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.businessBookkeeping.childImageSharp.gatsbyImageData}
                           alt="Business Bookkeeping"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Business Bookkeeping</h3>
                     <p className="mb-0">We’ll reconcile your business revenue, expenses, and bank statements each month.</p>
                     <AniLink fade to="/business-bookkeeping-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
                  <div className="group relative">
                     <div className="overflow-hidden mb-6">
                        <GatsbyImage
                           image={data.financialReporting.childImageSharp.gatsbyImageData}
                           alt="Financial Reporting"
                           className="transform scale-100 md:group-hover:scale-110 transition-all duration-500 ease-linear"
                        />
                     </div>
                     <h3 className="text-lg mb-2">Financial Reporting</h3>
                     <p className="mb-0">Because cash flow management is more important than a bank balance or revenue growth.</p>
                     <AniLink fade to="/financial-reporting-services/">
                        <span className="link-overlay"></span>
                     </AniLink>
                  </div>
               </div>
            </div>
         </section>

         <CallToAction subText="Get the small business management services you need. Call or request a quote today!" />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/Business Management.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Business Management.jpg" }) {
         publicURL
      }
      hero: file(relativePath: { eq: "services/Business Management/1.0 Business Management.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      intro: file(relativePath: { eq: "services/Business Management/2.0 Business Management Intro.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      personalBookkeeping: file(relativePath: { eq: "services/Personal Bookkeeping Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessBookkeeping: file(relativePath: { eq: "services/Business Bookkeeping.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      businessManagement: file(relativePath: { eq: "services/Business Management Services.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
      financialReporting: file(relativePath: { eq: "services/Financial Reporting.jpg" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
         }
      }
   }
`;
export default Page;
